import logo from '../Images/logo_horizontal.png'
import '../App.css';
import {Button, Grid} from "@mui/material";
import {css} from "@emotion/css";
import {Link} from "react-router-dom";

export default function Header() {
    const svgIcons = css`
      color: #000;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (min-width: 641px) {
        margin-inline:10px;
      }
    `
    const headerContainer = css`
      justify-content: space-between;
      @media (max-width: 865px) {
        justify-content: center;
      }
    `
    const hotBar = css`
      justify-content: flex-end;
      @media (max-width: 865px) {
        justify-content: center;
      }
    `
    const hotBarItems = css`
      background-color: #f9f9f9;
      border-top-right-radius: 25px;
      border-top-left-radius: 25px;
      display: flex;
      align-items: center;
    `
    const hotBarLinks = css`
      text-decoration: none
    `
    const companyLogo = css`
      height: 15vh;
      overflow: visible;
      width: auto;
    `
    const header = css`
      background-color: #2074d4;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-inline: 2%;
    `
    const contactContainer = css `
      @media (min-width: 641px) {
        display:flex;
      }
    `
    return (
        <div className={header}>
            <Grid container alignItems="center" className={headerContainer} display="flex">
                <Grid item justifyContent="center" alignItems="center">
                    <Link to="/" className={hotBarLinks}>
                        <img src={logo} className={companyLogo} alt="Savidant Tech Company Logo"/>
                    </Link>
                </Grid>
                <Grid item
                      className={contactContainer}
                      alignItems="center">
                    {/*<div className={svgIcons}>
                        <svg
                            viewBox="0 0 1024 1024"
                            fill="currentColor"
                            height="3em"
                            width="3em"
                        >
                            <path
                                d="M885.6 230.2L779.1 123.8a80.83 80.83 0 00-57.3-23.8c-21.7 0-42.1 8.5-57.4 23.8L549.8 238.4a80.83 80.83 0 00-23.8 57.3c0 21.7 8.5 42.1 23.8 57.4l83.8 83.8A393.82 393.82 0 01553.1 553 395.34 395.34 0 01437 633.8L353.2 550a80.83 80.83 0 00-57.3-23.8c-21.7 0-42.1 8.5-57.4 23.8L123.8 664.5a80.89 80.89 0 00-23.8 57.4c0 21.7 8.5 42.1 23.8 57.4l106.3 106.3c24.4 24.5 58.1 38.4 92.7 38.4 7.3 0 14.3-.6 21.2-1.8 134.8-22.2 268.5-93.9 376.4-201.7C828.2 612.8 899.8 479.2 922.3 344c6.8-41.3-6.9-83.8-36.7-113.8z"/>
                        </svg>
                        Telephone: (902)213-3034
                    </div>*/}
                    <div className={svgIcons}>
                        <svg
                            viewBox="0 0 24 24"
                            fill="currentColor"
                            height="3em"
                            width="3em"
                        >
                            <path
                                d="M20 8l-8 5-8-5V6l8 5 8-5m0-2H4c-1.11 0-2 .89-2 2v12a2 2 0 002 2h16a2 2 0 002-2V6a2 2 0 00-2-2z"/>
                        </svg>
                        Email:&nbsp;<a style={{textDecoration:'none', color:"#000000"}} href="mailto:contact@savidant-tech.ca">contact@savidant-tech.ca</a>
                    </div>
                </Grid>
            </Grid>
            <Grid className={hotBar} container>
                <Grid item className={hotBarItems}>
                    <Link to="/" className={hotBarLinks}><Button>Home</Button></Link>
                    <Link to="/services" className={hotBarLinks}><Button>Services</Button></Link>
                    <Link to="/demo" className={hotBarLinks}><Button>Demo</Button></Link>
                    <Link to="/about" className={hotBarLinks}><Button>About</Button></Link>
                    <Link to="/contact" className={hotBarLinks}><Button>Contact</Button></Link>
                </Grid>
            </Grid>
        </div>
    );
}
