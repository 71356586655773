import '../App.css';
import {css} from "@emotion/css";
import {Alert, Button, Card, Grid, MenuItem, TextField, Typography} from "@mui/material";
import {useWindowDimensions} from "../Functions/WindowSize";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useState} from "react";
import axios from "axios";
import {useLocation} from "react-router-dom";
import {useMediaQuery} from "react-responsive";


export default function Contact() {
    const isMobile = useMediaQuery({query: '(max-width: 641px)'})
    const {height} = useWindowDimensions();
    const location = useLocation()
    const {subject} = location.state != null ? location.state : ""
    const containerHeight = height * 0.85
    const background = css`
      background-size: cover;
      background: rgb(249, 249, 249);
      background: linear-gradient(180deg, rgba(249, 249, 249, 1) 1%, rgba(32, 116, 212, 1) 100%);
      margin-bottom: -8px;
      display: flex;
      align-items: center;
      flex-direction: column;
    `
    const hrStyle = css`
      border: 0;
      width: 75%;
      height: 1px;
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
    `
    const inputField = css`
      width: 100%;
      padding-left: 5%;
      margin-left: 3%;
    `
    const triInputGrid = css`
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media (max-width: 641px) {
        flex-direction: column !important;
      }
    `
    const errorContainer = css`
      display: flex;
      align-items: center;
      color: #FF0000;
      font-size: small;
    `
    const inputContainer = css`
      width: 25%;
      @media (max-width: 641px) {
        width: 100%;
      }
    `
    const cardStyle = css`
      width: 95%;
      display: inline-block;
      padding-top: 2%;
      margin-top: 2%;
      padding-bottom: 1%;
      @media (max-width: 641px) {
        padding-bottom: 5%;
        margin-bottom:5%;
      }
    `
    const submitContainer = css`
      padding-top: 1%; 
      padding-bottom: 1%;
    `
    const products = [
        {
            value: 'General',
            label: 'General',
        },
        {
            value: 'WordPress Basic',
            label: 'WordPress Basic',
        },
        {
            value: 'WordPress Advanced',
            label: 'WordPress Advanced',
        },
        {
            value: 'PhP and JavaScript Website',
            label: 'PhP and JavaScript Website',
        },
        {
            value: 'Search Engine Optimization',
            label: 'Search Engine Optimization',
        },
        {
            value: 'Android/iOS Basic',
            label: 'Android/iOS Basic',
        },
        {
            value: 'Android/iOS Advanced',
            label: 'Android/iOS Advanced',
        },
        {
            value: 'Software Contracting',
            label: 'Software Contracting',
        },
    ];
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required("Name is required."),
        email: Yup.string()
            .required("Email is required.")
            .email("Email invalid."),
        subject: Yup.string()
            .required("Reason is required."),
        message: Yup.string()
            .required("Message is required."),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            name: "",
            email: "",
            subject: subject,
            phone: "",
            message: ""
        },
        onSubmit: (values) => {
            console.log(JSON.stringify(values, null, 2));
            axios({
                method: "POST",
                url: "https://us-central1-savidant-tech.cloudfunctions.net/sendEmail",
                data: formik.values
            })
                .then(() => {
                    handleServerSuccess(true, "Contact message sent.")
                    setServerError(null)
                })
                .catch(() => {
                    handleServerError(true, "Contact message failed to send.")
                });
        },
    });
    const [serverSuccess, setServerSuccess] = useState();
    const [serverError, setServerError] = useState();
    const handleServerSuccess = (ok, msg) => {
        setServerSuccess({ok, msg});
    };
    const handleServerError = (ok, msg) => {
        setServerError({ok, msg});
    };
    return (
        <div className="App" style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
            <div className={background} style={{minHeight: containerHeight}}>
                <Card className={cardStyle} variant="outlined">
                    <Typography variant="h2" gutterBottom style={{fontWeight: 'bold'}}>
                        Contact
                    </Typography>
                    <hr className={hrStyle}/>
                    <Grid container style={{width: '90%', display: 'inline-block'}}>
                        {serverError && (
                            <Alert severity="error"> {serverError.msg}</Alert>
                        )}
                        {serverSuccess != null &&
                            <Alert severity="success">{serverSuccess.msg}</Alert>
                        }
                        <form onSubmit={formik.handleSubmit}>
                            <Grid item className={triInputGrid}>
                                <div className={inputContainer}>
                                    <TextField
                                        variant="filled"
                                        id="name"
                                        fullWidth
                                        type="string"
                                        name="name"
                                        size="small"
                                        className={inputField}
                                        label="Name"
                                        margin="normal"
                                        error={formik.errors.name && formik.touched.name}
                                        {...formik.getFieldProps("name")}
                                    />
                                    {formik.touched.name && formik.errors.name ? (
                                        <div className={errorContainer}>
                                            <svg
                                                viewBox="0 0 512 512"
                                                fill="currentColor"
                                                height="1em"
                                                width="1em"
                                            >
                                                <path
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeMiterlimit={10}
                                                    strokeWidth={32}
                                                    d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
                                                />
                                                <path
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={32}
                                                    d="M250.26 166.05L256 288l5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 6z"
                                                />
                                                <path d="M256 367.91a20 20 0 1120-20 20 20 0 01-20 20z"/>
                                            </svg>
                                            &nbsp;{formik.errors.name}</div>
                                    ) : (formik.touched.name && formik.errors.name) || (formik.touched.email && formik.errors.email) ?  isMobile === false ?<div>&nbsp;</div> : null : null }
                                </div>
                                <div className={inputContainer}>
                                    <TextField
                                        variant="filled"
                                        fullWidth
                                        id="email"
                                        name="email"
                                        type="email"
                                        size="small"
                                        className={inputField}
                                        label="Email"
                                        margin="normal"
                                        error={formik.errors.email && formik.touched.email}
                                        {...formik.getFieldProps("email")}
                                    />
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className={errorContainer}>
                                            <svg
                                                viewBox="0 0 512 512"
                                                fill="currentColor"
                                                height="1em"
                                                width="1em"
                                            >
                                                <path
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeMiterlimit={10}
                                                    strokeWidth={32}
                                                    d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
                                                />
                                                <path
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={32}
                                                    d="M250.26 166.05L256 288l5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 6z"
                                                />
                                                <path d="M256 367.91a20 20 0 1120-20 20 20 0 01-20 20z"/>
                                            </svg>
                                            &nbsp;{formik.errors.email}</div>
                                    ) : (formik.touched.name && formik.errors.name) || (formik.touched.email && formik.errors.email) ?  isMobile === false ?<div>&nbsp;</div> : null : null }
                                </div>
                                <div className={inputContainer}>
                                    <TextField
                                        variant="filled"
                                        id="phone"
                                        fullWidth
                                        name="phone"
                                        type="string"
                                        size="small"
                                        className={inputField}
                                        label="Phone Number (optional)"
                                        margin="normal"
                                        {...formik.getFieldProps("phone")}
                                    />
                                    {formik.touched.phone && formik.errors.phone ? (
                                        <div className={errorContainer}>
                                            <svg
                                                viewBox="0 0 512 512"
                                                fill="currentColor"
                                                height="1em"
                                                width="1em"
                                            >
                                                <path
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeMiterlimit={10}
                                                    strokeWidth={32}
                                                    d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
                                                />
                                                <path
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={32}
                                                    d="M250.26 166.05L256 288l5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 6z"
                                                />
                                                <path d="M256 367.91a20 20 0 1120-20 20 20 0 01-20 20z"/>
                                            </svg>
                                            &nbsp;{formik.errors.phone}</div>
                                    ) : (formik.touched.name && formik.errors.name) || (formik.touched.email && formik.errors.email) ?  isMobile === false ?<div>&nbsp;</div> : null : null }
                                </div>
                            </Grid>
                            <Grid item>
                                <div style={{display: 'block', paddingTop:'1%'}}>
                                    <TextField
                                        id="subject"
                                        select
                                        fullWidth
                                        type="string"
                                        variant="filled"
                                        name="subject"
                                        label='Select a Product'
                                        style={{ width:'100%',display:'flex',justifyContent:'flex-start',alignItems:'flex-start'}}
                                        onChange={formik.handleChange}
                                        error={formik.errors.subject && formik.touched.subject}
                                        {...formik.getFieldProps("subject")}
                                    >
                                        {products.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                <div style={{ width:'100%',display:'flex',justifyContent:'flex-start',alignItems:'flex-start'}}>{option.label}</div>
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {formik.touched.subject && formik.errors.subject ? (
                                        <div className={errorContainer} style={{paddingTop: '8px'}}>
                                            <svg
                                                viewBox="0 0 512 512"
                                                fill="currentColor"
                                                height="1em"
                                                width="1em"
                                            >
                                                <path
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeMiterlimit={10}
                                                    strokeWidth={32}
                                                    d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
                                                />
                                                <path
                                                    fill="none"
                                                    stroke="currentColor"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth={32}
                                                    d="M250.26 166.05L256 288l5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 6z"
                                                />
                                                <path d="M256 367.91a20 20 0 1120-20 20 20 0 01-20 20z"/>
                                            </svg>
                                            &nbsp;{formik.errors.subject}</div>
                                    ) : null}
                                </div>
                            </Grid>

                            <Grid item>
                                <div style={{display: 'block',paddingTop:'1%'}}>
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        id="message"
                                        type="string"
                                        name="message"
                                        className={inputField}
                                        label="Message"
                                        margin="normal"
                                        multiline
                                        rows={4}
                                        error={formik.errors.message && formik.touched.message}
                                        {...formik.getFieldProps("message")}
                                    />
                                </div>
                                {formik.touched.message && formik.errors.message ? (
                                    <div className={errorContainer}>
                                        <svg
                                            viewBox="0 0 512 512"
                                            fill="currentColor"
                                            height="1em"
                                            width="1em"
                                        >
                                            <path
                                                fill="none"
                                                stroke="currentColor"
                                                strokeMiterlimit={10}
                                                strokeWidth={32}
                                                d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z"
                                            />
                                            <path
                                                fill="none"
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={32}
                                                d="M250.26 166.05L256 288l5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 6z"
                                            />
                                            <path d="M256 367.91a20 20 0 1120-20 20 20 0 01-20 20z"/>
                                        </svg>
                                        &nbsp;{formik.errors.message}</div>
                                ) : null}
                            </Grid>
                            <Grid item className={submitContainer}>
                                <Button
                                    onClick={formik.handleSubmit}
                                    type="submit"
                                    variant="contained"
                                    style={{width: '75%'}}>
                                    Submit
                                </Button>
                            </Grid>
                        </form>
                    </Grid></Card>
            </div>
        </div>
    );
}
