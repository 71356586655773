import '../App.css';
import {css} from "@emotion/css";
import {Card, Divider, Grid, Typography} from "@mui/material";
import avatar from "../Images/avatar.webp"
import CCNA from "../Images/CCNA_certificate.webp"
import aPlus from "../Images/A+_certificate.webp"
import {useMediaQuery} from "react-responsive";


export default function About() {
    const isMobile = useMediaQuery({query: '(max-width: 641px)'})
    const background = css`
      background: rgb(249, 249, 249);
      background: linear-gradient(180deg, rgba(249, 249, 249, 1) 1%, rgba(32, 116, 212, 1) 100%);
      margin-bottom: -8px;
    `
    const hrStyle = css`
      border: 0;
      width: 75%;
      height: 1px;
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
    `
    const contact = css`
      color: #000;
      display: flex;
      justify-content: center;
      align-items: center;
    `
    const contactContainer = css`
      width:100%;
      justify-content: space-around;
      display: flex;
      padding-top: 2%;
    `
    const certImages = css`
      padding-top: 3%;
      max-width: 100%;
      @media (min-width: 641px) {
        max-width: 45%;
      }
    `
    const mainCard = css`
      width: 95%;
      display: inline-block;
      padding-top: 2%;
      margin-top: 2%;
      margin-bottom: 2%;
    `
    const cardContainer = css`
      margin-top: 5%;
      margin-bottom: 5%;
    `
    const leftThird = css`
      border-right: 1px solid;
    `
    const rightTwoThird = css`
      color: #6D6D6D;
      padding-left: 10%;
      justify-content: center;
      display: inline-block;
      align-items: center;
    `
    const avatarStyle = css`
      width: 95%;
      max-width: 300px;
    `
    const italicize = css`
      font-style: italic
    `
    const certContainer = css`
      display: flex;
      justify-content: space-between;
      margin-top: 5%;
      @media (max-width: 641px) {
        display: inline-block
      }
    `
    return (
        <div className="App">
            <div className={background}>
                <Card className={mainCard}
                      variant="outlined"><Typography variant="h2" gutterBottom style={{fontWeight: 'bold'}}>
                    About Savidant Technology Company
                </Typography>
                    <hr className={hrStyle}/>
                    <Grid container className={cardContainer} style={{
                        width: '90%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        justifySelf: 'center'
                    }}>
                        {isMobile ?
                            <div style={{display: 'inline-block', marginLeft: 'auto', marginRight: 'auto'}}>
                                <Grid item xs={12} display="inline-block">
                                    <img src={avatar} className={avatarStyle} alt={"Avatar"}/>
                                    <Divider variant="middle" style={{marginBottom:'3%', paddingTop:'3%'}} />
                                    <Typography className={italicize}>
                                        Alex Savidant
                                    </Typography>
                                    <Typography className={italicize}>
                                        CEO/Lead Programmer
                                    </Typography>
                                    <div className={contactContainer}>
                                        <div className={contact}>
                                            Email: <br/><wbr/>alex
                                            <wbr/>
                                            @savidant-tech.ca
                                        </div>
                                    </div>
                                    <Divider variant="middle" style={{marginBottom:'3%', paddingTop:'3%'}} />
                                </Grid>
                                <Grid item>
                                    <div> Founded in 2023, Savidant Technology Company specializes in real-time
                                        applications,
                                        voice-to-text
                                        accessibility, geolocation solutions, peer-to-peer communications, RESTful APIs,
                                        and
                                        cloud technologies.
                                        <br/><br/>Our team maintains certifications in network administration, providing
                                        a
                                        foundational understanding of backbone
                                        internet protocols and security essentials. With this underlying knowledge of
                                        the
                                        internet, intelligent
                                        decisions can be made to provide high performance optimization to the projects
                                        being
                                        developed.
                                    </div>
                                    <div className={certContainer}>
                                        <img src={CCNA} className={certImages} alt={"Cisco Certification"}/>
                                        <img src={aPlus} className={certImages} alt={"A+ Certification"}/>
                                    </div>
                                </Grid>
                            </div> :
                            <>
                                <Grid className={leftThird} item xs={4}>
                                    <img src={avatar} className={avatarStyle} alt={"Avatar"}/>
                                    <Divider variant="middle" style={{marginBottom:'3%', paddingTop:'3%'}} />
                                    <Typography className={italicize}>
                                        Alex Savidant
                                    </Typography>
                                    <Typography className={italicize}>
                                        CEO/Lead Programmer
                                    </Typography>
                                    <br/>
                                    <div className={contact}>
                                        Email: <wbr/>alex
                                        <wbr/>
                                        @savidant-tech.ca
                                    </div>
                                </Grid>
                                <Grid item xs={8} className={rightTwoThird}>
                                    <div> Founded in 2023, Savidant Technology Company specializes in real-time
                                        applications,
                                        voice-to-text
                                        accessibility, geolocation solutions, peer-to-peer communications, RESTful APIs,
                                        and
                                        cloud technologies.
                                        <br/><br/>Our team maintains certifications in network administration, providing
                                        a
                                        foundational understanding of backbone
                                        internet protocols and security essentials. With this underlying knowledge of
                                        the
                                        internet, intelligent
                                        decisions can be made to provide high performance optimization to the projects
                                        being
                                        developed.
                                    </div>
                                    <div className={certContainer}>
                                        <img src={CCNA} className={certImages} alt={"Cisco Certification"}/>
                                        <img src={aPlus} className={certImages} alt={"A+ Certification"}/>
                                    </div>
                                </Grid></>
                        }
                    </Grid>
                </Card>
            </div>
        </div>
    );
}

