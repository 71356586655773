import React, {useEffect} from 'react'
import {Route, Routes, useLocation} from 'react-router-dom'
import Home from "./Pages/Home";
import Services from "./Pages/Services";
import Demo from "./Pages/Demo";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import {useMediaQuery} from "react-responsive";

export default function App() {
    const baseTitle = "Savidant Technology Company | "

    const isMobile = useMediaQuery({query: '(max-width: 950px)'})
    useEffect(() => {
        // Change zoom level on mount
        !isMobile ?
        document.body.style.zoom = "125%"
    : document.body.style.zoom = "100%"
    }, [isMobile]);

    let Location = useLocation();
    useEffect(() => {
        const titles = [
            {path: '/', title: 'Home'},
            {path: '/services', title: 'Services'},
            {path: '/demo', title: 'Try our application'},
            {path: '/about', title: 'About Us'},
            {path: '/contact', title: 'Contact'},
        ]
        const pageTitle = titles.find(item => item.path === Location.pathname)
        if (pageTitle && pageTitle.title) {
            document.title = baseTitle + pageTitle.title
        }
    }, [Location])
    return (
        <Routes>
            <Route exact path="/" element={<Home/>}/>
            <Route exact path="/services" element={<Services/>}/>
            <Route exact path="/demo" element={<Demo/>}/>
            <Route exact path="/about" element={<About/>}/>
            <Route exact path="/contact" element={<Contact/>}/>
        </Routes>
    )
}
