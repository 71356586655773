import '../App.css';
import {css} from "@emotion/css";

export default function Footer() {
    const hrStyle = css `
      border: 0;
      height: 1px;
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
    `
    return (
        <div style={{paddingBottom: '5px', backgroundColor: '#2074d4'}}>
            <hr className={hrStyle} style={{backgroundColor:'#2074d4'}}/>
            <footer style={{
                display: 'flex',
                textAlign: 'center',
                fontSize:'0.75rem',
                flexDirection: 'row',
                alignItems:'center',
                justifyContent: 'center',
                backgroundColor: '#2074d4'
            }}>
                © 2023 Savidant Technology Company. All rights reserved.
            </footer>
        </div>
    );
}
